<template>
  <section
    v-if="Object.keys(pricing).length"
    id="pricing-plan"
  >
    <!-- title text and switch button -->
    <div class="text-center">
      <h1 class="mt-5">
        Pricing Plans
      </h1>
      <p class="mb-2 pb-75">
        All plans include 40+ advanced tools and features to boost your product. Choose the best plan to fit your needs.
      </p>
      <div class="d-flex align-items-center justify-content-center mb-5 pb-50">
        <h6 class="mr-1 mb-0">
          Monthly
        </h6>
        <b-form-checkbox
          id="priceSwitch"
          v-model="status"
          name="price-switch"
          value="annually"
          unchecked-value="monthly"
          switch
          @input="togglePlan"
        />
        <h6 class="ml-50 mb-0">
          Annually
        </h6>
      </div>
    </div>
    <!--/ title text and switch button -->

    <!-- pricing plan cards -->
    <b-row class="pricing-card">
      <b-col
        offset-sm="2"
        sm="10"
        md="12"
        offset-lg="2"
        lg="10"
        class="mx-auto"
      >
        <b-row>
          <b-col md="4">
            <b-card
              align="center"
            >
              <!-- img -->
              <b-img
                v-if="pricing.basicPlan.img"
                :src="pricing.basicPlan.img"
                class="mb-2 mt-5"
                alt="basic svg img"
              />
              <!--/ img -->
              <h3>{{ pricing.basicPlan.title }}</h3>
              <b-card-text>
                {{ pricing.basicPlan.subtitle }}
              </b-card-text>

              <!-- annual plan -->
              <div class="annual-plan">
                <div class="plan-price mt-2">
                  <sup class="font-medium-1 font-weight-bold text-primary">$</sup>
                  <span class="pricing-basic-value font-weight-bolder text-primary">{{ monthlyPlanShow ? pricing.basicPlan.monthlyPrice : pricing.basicPlan.yearlyPlan.perMonth }}</span>
                  <sub class="pricing-duration text-body font-medium-1 font-weight-bold">/month</sub>
                </div>
                <small
                  v-show="!monthlyPlanShow"
                  class="annual-pricing text-muted"
                >USD {{ pricing.basicPlan.yearlyPlan.totalAnual }} / year</small>
              </div>
              <!--/ annual plan -->

              <!-- plan benefit -->
              <b-list-group class="list-group-circle text-left">
                <b-list-group-item
                  v-for="(data, index) in pricing.basicPlan.planBenefits"
                  :key="index"
                >
                  {{ data }}
                </b-list-group-item>
              </b-list-group>
              <!--/ plan benefit -->

              <!-- buttons -->
              <b-button
                v-ripple.400="'rgba(40, 199, 111, 0.15)'"
                block
                class="mt-2"
                variant="outline-primary"
                :href="monthlyPlanShow ? pricing.basicPlan.stripe_monthly_link : pricing.basicPlan.stripe_yearly_link"
                target="_blank"
                :disabled="isLoading"
              >
                Upgrade
              </b-button>
            </b-card>
          </b-col>
          <b-col md="4">
            <b-card
              class="popular"
              align="center"
            >
              <div
                v-show="pricing.standardPlan.popular"
                class="pricing-badge text-right"
              >
                <b-badge
                  variant="light-primary"
                  pill
                >
                  Popular
                </b-badge>
              </div>
              <!-- img -->
              <b-img
                v-if="pricing.standardPlan.img"
                :src="pricing.standardPlan.img"
                class="mb-1"
                alt="svg img"
              />
              <!--/ img -->
              <h3>{{ pricing.standardPlan.title }}</h3>
              <b-card-text>{{ pricing.standardPlan.subtitle }}</b-card-text>

              <!-- annual plan -->
              <div class="annual-plan">
                <div class="plan-price mt-2">
                  <sup class="font-medium-1 font-weight-bold text-primary">$</sup>
                  <span class="pricing-basic-value font-weight-bolder text-primary">{{ monthlyPlanShow ? pricing.standardPlan.monthlyPrice : pricing.standardPlan.yearlyPlan.perMonth }}</span>
                  <sub class="pricing-duration text-body font-medium-1 font-weight-bold">/month</sub>
                </div>
                <small
                  v-show="!monthlyPlanShow"
                  class="annual-pricing text-muted"
                >USD {{ pricing.standardPlan.yearlyPlan.totalAnual }} / year</small>
              </div>
              <!--/ annual plan -->

              <!-- plan benefit -->
              <b-list-group class="list-group-circle text-left">
                <b-list-group-item
                  v-for="(data, index) in pricing.standardPlan.planBenefits"
                  :key="index"
                >
                  {{ data }}
                </b-list-group-item>
              </b-list-group>
              <!--/ plan benefit -->

              <!-- buttons -->
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                block
                class="mt-2"
                variant="primary"
                :href="monthlyPlanShow ? pricing.standardPlan.stripe_monthly_link : pricing.standardPlan.stripe_yearly_link"
                target="_blank"
                :disabled="isLoading"
              >
                Upgrade
              </b-button>
            </b-card>
          </b-col>
          <b-col md="4">
            <b-card
              align="center"
            >
              <!-- img -->
              <b-img
                v-if="pricing.enterprisePlan.img"
                :src="pricing.enterprisePlan.img"
                class="mb-2"
                alt="enterprise svg img"
              />
              <!--/ img -->
              <h3>{{ pricing.enterprisePlan.title }}</h3>
              <b-card-text>{{ pricing.enterprisePlan.subtitle }}</b-card-text>

              <!-- annual plan -->
              <div class="annual-plan">
                <div class="plan-price mt-2">
                  <sup class="font-medium-1 font-weight-bold text-primary">$</sup>
                  <span class="pricing-basic-value font-weight-bolder text-primary">{{ monthlyPlanShow ? pricing.enterprisePlan.monthlyPrice : pricing.enterprisePlan.yearlyPlan.perMonth }}</span>
                  <sub class="pricing-duration text-body font-medium-1 font-weight-bold">/month</sub>
                </div>
                <small
                  v-show="!monthlyPlanShow"
                  class="annual-pricing text-muted"
                >USD {{ pricing.enterprisePlan.yearlyPlan.totalAnual }} / year</small>
              </div>
              <!--/ annual plan -->

              <!-- plan benefit -->
              <b-list-group
                v-for="(data, index) in pricing.enterprisePlan.planBenefits"
                :key="index"
                class="list-group-circle text-left"
              >
                <b-list-group-item>
                  {{ data }}
                </b-list-group-item>
              </b-list-group>
              <!--/ plan benefit -->

              <!-- buttons -->
              <b-button
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                block
                class="mt-2"
                variant="outline-primary"
                :href="monthlyPlanShow ? pricing.enterprisePlan.stripe_monthly_link : pricing.enterprisePlan.stripe_yearly_link"
                target="_blank"
                :disabled="isLoading"
              >
                Upgrade
              </b-button>
            </b-card>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
    <!--/ pricing plan cards -->
  </section>
</template>

<script>
import {
  BFormCheckbox, BRow, BCol, BCard, BImg, BCardText, BListGroup, BListGroupItem, BButton, BBadge,
} from 'bootstrap-vue'
import AppCollapse from '@core/components/app-collapse/AppCollapse.vue'
import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue'
import Ripple from 'vue-ripple-directive'
import { collection, getFirestore, onSnapshot,addDoc, doc,getDoc,updateDoc  } from 'firebase/firestore'
import { onAuthStateChanged, getAuth } from 'firebase/auth'
import { db } from '@/firebase/index'

/* eslint-disable global-require */
export default {
  components: {
    BFormCheckbox,
    BButton,
    BCardText,
    BListGroup,
    BListGroupItem,
    BRow,
    BCol,
    BCard,
    BBadge,
    BImg,
    AppCollapseItem,
    AppCollapse,
  },
  directives: {
    Ripple,
  },
  computed: {
    PaymentPlan() {
      return this.PaymentPlan = this.$store.getters.getPaymentPlan
    },
  },
  data() {
    return {
      isLoading: false,
    status: 'monthly',
    monthlyPlanShow: true,
    pricing: {
      basicPlan: {
        stripe_monthly_link: 'https://buy.stripe.com/dR6bK66Xe4Q3co0eV2?utm_content=Beginner',
        stripe_yearly_link: 'https://buy.stripe.com/cN2aG2gxO0zNafSaEN?utm_content=Beginner',
        title: 'Basic',
        img: require('@/assets/images/illustration/Pot1.svg'),
        subtitle: 'A simple start for everyone',
        monthlyPrice: 8.99,
        yearlyPlan: {
          perMonth: 7,
          totalAnual: 79.99,
        },
        planBenefits: [
          'Stock Ranking',
          'Stock Valuation',
          'Profitbaility Analysis',
          'Growth Analysis',
          'Financial Strength Analysis',
          'Watchlist',
        ],
        popular: false,
      },
      standardPlan: {
        stripe_monthly_link: 'https://buy.stripe.com/4gw01o2GY0zNfAc14a?utm_content=Analyst',
        stripe_yearly_link: 'https://buy.stripe.com/3csaG21CUeqD73G00a?utm_content=Analyst',
        title: 'Standard',
        img: require('@/assets/images/illustration/Pot2.svg'),
        subtitle: 'For small to medium businesses',
        monthlyPrice: 14.99,
        yearlyPlan: {
          perMonth: 11,
          totalAnual: 134.99,
        },
        planBenefits: [
          'Stock Ranking',
          'Stock Valuation',
          'Profitbaility Analysis',
          'Growth Analysis',
          'Financial Strength Analysis',
          'Watchlist',
          'Institutional Holders',
          'Pre-Built Screeners',
        ],
        popular: true,
      },
      enterprisePlan: {
        stripe_monthly_link: 'https://buy.stripe.com/28o4hEepG1DR87K9AH',
        stripe_yearly_link: 'https://buy.stripe.com/28odSe1CU1DR3Ru9AL',
        title: 'Enterprise',
        img: require('@/assets/images/illustration/Pot3.svg'),
        subtitle: 'Solution for big organizations',
        monthlyPrice: 29.99,
        yearlyPlan: {
          perMonth: 22,
          totalAnual: 259.99,
        },
        planBenefits: [
          'Stock Ranking',
          'Stock Valuation',
          'Profitbaility Analysis',
          'Growth Analysis',
          'Financial Strength Analysis',
          'Watchlist',
          'Institutional Holders',
          'Pre-Built Screeners',
          'Custom Screener',
          'Insider Activity',
          'Portfolio-Tracking',
        ],
        popular: false,
      },
    qandA: [
      {
        question: 'Does my subscription automatically renew?',
        ans:
          'Pastry pudding cookie toffee bonbon jujubes jujubes powder topping. Jelly beans gummi bears sweet roll bonbon muffin liquorice. Wafer lollipop sesame snaps. Brownie macaroon cookie muffin cupcake candy caramels tiramisu. Oat cake chocolate cake sweet jelly-o brownie biscuit marzipan. Jujubes donut marzipan chocolate bar. Jujubes sugar plum jelly beans tiramisu icing cheesecake.',
      },
      {
        question: 'Can I store the item on an intranet so everyone has access?',
        ans:
          'Tiramisu marshmallow dessert halvah bonbon cake gingerbread. Jelly beans chocolate pie powder. Dessert pudding chocolate cake bonbon bear claw cotton candy cheesecake. Biscuit fruitcake macaroon carrot cake. Chocolate cake bear claw muffin chupa chups pudding.',
      },
      {
        question: 'Am I allowed to modify the item that I purchased?',
        ans:
          'Tart gummies dragée lollipop fruitcake pastry oat cake. Cookie jelly jelly macaroon icing jelly beans soufflé cake sweet. Macaroon sesame snaps cheesecake tart cake sugar plum. Dessert jelly-o sweet muffin chocolate candy pie tootsie roll marzipan. Carrot cake marshmallow pastry. Bonbon biscuit pastry topping toffee dessert gummies. Topping apple pie pie croissant cotton candy dessert tiramisu.',
      },
    ],
  },
    }
  },
  mounted() {
    this.UpGrade() 
  },
  methods: {
    togglePlan() {
      this.monthlyPlanShow = this.status === 'monthly';
    },
    async UpGrade() {
      const auth = getAuth();
      onAuthStateChanged(auth, async(user) => {
        if (user) {
          // User is signed in, see docs for a list of available properties
          // https://firebase.google.com/docs/reference/js/auth.user
          const uid = user.uid;
          const docRef = doc(db, "users", uid);
          const docSnap = await getDoc(docRef);

            if (docSnap.exists() && this.$route.query.session_id != undefined) {
              console.log(this.$route.query.session_id)
              console.log(this.$route.query.utm_content)
                await updateDoc(docRef, {
                  Session_ID: this.$route.query.session_id,
                  FreeTrial: null,
                  Plan: this.$route.query.utm_content,
                });
                this.$store.dispatch('setPaymentPlan',this.$route.query.utm_content)
                this.$swal({
                  title: 'Payment Succeded',
                  text: 'You have been promoted to ' + this.$route.query.utm_content,
                  icon: 'success',
                  customClass: {
                    confirmButton: 'btn btn-primary',
                  },
                  buttonsStyling: false,
                })
              // if(this.$route.query.session_id != undefined) {
              //   console.log('Jetzt sollte es geklappt haben auch wenss hässlich ist')
              // }
                // console.log("Document data:", docSnap.data().Folio);
                // const data = docSnap.data().Folio
                // this.$store.dispatch('setCurrentPortfolio',data)
            } else {

            // doc.data() will be undefined in this case
                // console.log("No such document!");
            }
          // ...
        } else {
          
          // User is signed out
          // ...
        }
});
    }
  },

}
/* eslint-disable global-require */
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-pricing.scss';
</style>

